import React from "react"

// import logo from "../../assets/img/location/kataliklogo.png"
import { useSetRecoilState } from "recoil"
import { citiesModalOpen } from "./atoms"
import { Map, Placemark, YMaps } from "react-yandex-maps"
import {
  citySwitchAddress1,
  citySwitchAddress2,
  citySwitchDescription,
  citySwitchMapCenter,
  citySwitchTelNumber,
  citySwitchTelNumberStr,
} from "../requisites"

export default function LocationSection({ city }) {
  const setOpen = useSetRecoilState(citiesModalOpen)

  return (
    <section className="location" id="location">
      <div className="container">
        <h2 className="location-title section-title section-title--medium text-center">
          Пункты приема катализаторов и контакты
        </h2>
        <ul className="location-navigation">
          <li className="location-navigation__item">
            <button
              onClick={() => setOpen(true)}
              className={
                city === "Калуга"
                  ? "location-navigation__link active"
                  : "location-navigation__link2"
              }
            >
              Калуга
            </button>
          </li>
          <li className="location-navigation__item hidden md:block">
            <button
              onClick={() => setOpen(true)}
              className={
                city === "Иваново"
                  ? "location-navigation__link active"
                  : "location-navigation__link2"
              }
            >
              Иваново
            </button>
          </li>
          <li className="location-navigation__item hidden md:block">
            <button
              onClick={() => setOpen(true)}
              className={
                city === "Брянск"
                  ? "location-navigation__link active"
                  : "location-navigation__link2"
              }
            >
              Брянск
            </button>
          </li>
          <li className="location-navigation__item hidden md:block">
            <button
              onClick={() => setOpen(true)}
              className={
                city === "Тула"
                  ? "location-navigation__link active"
                  : "location-navigation__link2"
              }
            >
              Тула
            </button>
          </li>
          <li className="location-navigation__item hidden md:block">
            <button
              onClick={() => setOpen(true)}
              className={
                city === "Смоленск"
                  ? "location-navigation__link active"
                  : "location-navigation__link2"
              }
            >
              Смоленск
            </button>
          </li>
          <li className="location-navigation__item hidden md:block">
            <button
              onClick={() => setOpen(true)}
              className={
                city === "Воронеж"
                  ? "location-navigation__link active"
                  : "location-navigation__link2"
              }
            >
              Воронеж
            </button>
          </li>
          <li className="location-navigation__item hidden md:block">
            <button
              onClick={() => setOpen(true)}
              className={
                city === "Орел"
                  ? "location-navigation__link active"
                  : "location-navigation__link2"
              }
            >
              Орел
            </button>
          </li>
          <li className="location-navigation__item">
            <button
              onClick={() => setOpen(true)}
              className="location-navigation__link2"
            >
              Все города
            </button>
          </li>
        </ul>
        <div
          className="location-map wow zoomIn"
          // data-wow-delay="0.5s"
          style={
            {
              // visibility: "visible",
              // animationDelay: "0.5s",
              // animationName: "zoomIn",
            }
          }
        >
          <div className="location-map-block">
            <div className="location-map-block__address">
              {citySwitchAddress1(city)}
              <br /> {citySwitchAddress2(city)}
            </div>
            <div className="location-map-block__properties">
              <div className="location-map-block__properti">
                <span className="location-map-block__properti-key">Будни </span>
                <span className="location-map-block__properti-value">
                  с 10:00 до 19:00
                </span>
              </div>
              <div className="location-map-block__properti">
                <span className="location-map-block__properti-key">Сб </span>
                <span className="location-map-block__properti-value">
                  с 10:00 до 14:00
                </span>
              </div>
            </div>
            <div className="location-map-block__properti">
              <span className="location-map-block__properti-key">тел.: </span>
              <span className="location-map-block__properti-value">
                <a href={`tel:${citySwitchTelNumber(city)}`}>
                  {citySwitchTelNumberStr(city)}
                </a>
              </span>
            </div>
            <div className="location-map-block__properti">
              <span className="location-map-block__properti-key">тел.: </span>
              <span className="location-map-block__properti-value">
                <a href="tel:88002506625">8 (800) 250-66-25</a>
              </span>
            </div>
            <div className="location-map-block__properti">
              <span className="location-map-block__properti-key">e-mail: </span>
              <span className="location-map-block__properti-value">
                <a href="mailto:katalikauto@yandex.ru">katalikauto@yandex.ru</a>
              </span>
            </div>

            <div className="location-map-block__dopinfo">
              Заявки на почту <span> принимаем круглосуточно</span>
            </div>
            <p className="location-map-block__descr">
              {citySwitchDescription(city)} Мы гарантируем проведение анализа и
              выплату в день получения отработанного материала. Наша компания
              скупает катализаторы и сажевые фильтры по высоким ценам до 75%
              LME.
            </p>
          </div>
          <div id="map" style={{}}>
            <YMaps>
              <Map
                defaultState={{ center: citySwitchMapCenter(city), zoom: 13 }}
                style={{ width: "100%", height: "652px" }}
              >
                <Placemark geometry={citySwitchMapCenter(city)} />
              </Map>
            </YMaps>
          </div>
        </div>
      </div>
    </section>
  )
}
