import React from "react"

import { useSetRecoilState } from "recoil"
import { callbackModalOpen } from "../Home/atoms"

import Header from "../Home/Header"

import mainScreenImg from "../../assets/img/main-screen/main-screen-img.png"
import feature1Png from "../../assets/img/features/1.png"
import feature2Png from "../../assets/img/features/2.png"
import feature3Png from "../../assets/img/features/3.png"
import feature4Png from "../../assets/img/features/4.png"

export default function Screen() {
  const setCallbackModalOpen = useSetRecoilState(callbackModalOpen)

  return (
    <div className="screen">
      <Header />
      <div className="screen-body">
        <div className="container">
          <div className="screen-body-info ">
            <h1 className="screen-body-info__title big-title">
              404! <span>такой</span> страницы <span>к сожалению уже нет</span>
            </h1>
            <h4 className="screen-body-info__subtitle">
              Позвоните нам, мы уточним
            </h4>

            <div className="screen-body-info__actions">
              <button
                onClick={() => setCallbackModalOpen(true)}
                className="button button--solid-orange"
              >
                Вызвать специалиста
              </button>
              <button
                onClick={() => setCallbackModalOpen(true)}
                className="button button--o-white"
              >
                Узнать стоимость
              </button>
            </div>
          </div>
          <img
            src={mainScreenImg}
            //data-wow-duration="1.1s"
            alt="screen-body-img"
            className="screen-body-img wow fadeInRight"
            style={{
              visibility: "visible",
              // animationDuration: "1.1s",
              // animationName: "fadeInRight",
            }}
          />
        </div>
      </div>
      <div className="screen-bottom">
        <div className="container">
          <div className="features">
            <div
              className="feature wow fadeInUp"
              // data-wow-delay="0s"
              style={
                {
                  // visibility: "visible",
                  // animationDelay: "0s",
                  // animationName: "fadeInUp",
                }
              }
            >
              <div className="feature__img">
                <img src={feature1Png} alt="feature 1" />
              </div>
              <div className="feature__info">
                <div className="feature__num">7 лет</div>
                <div className="feature__descr">на рынке</div>
              </div>
            </div>
            <div
              className="feature wow fadeInUp"
              // data-wow-delay="0.3s"
              style={
                {
                  // visibility: "visible",
                  // animationDelay: "0.3s",
                  // animationName: "fadeInUp",
                }
              }
            >
              <div className="feature__img">
                <img src={feature2Png} alt="feature 2" />
              </div>
              <div className="feature__info">
                <div className="feature__num">&gt; 3 тыс.</div>
                <div className="feature__descr">довольных клиентов</div>
              </div>
            </div>
            <div
              className="feature wow fadeInUp"
              // data-wow-delay="0.6s"
              style={
                {
                  // visibility: "visible",
                  // animationDelay: "0.6s",
                  // animationName: "fadeInUp",
                }
              }
            >
              <div className="feature__img">
                <img src={feature3Png} alt="feature 3" />
              </div>
              <div className="feature__info">
                <div className="feature__num">15</div>
                <div className="feature__descr">
                  представительств в регионах
                </div>
              </div>
            </div>
            <div
              className="feature wow fadeInUp"
              // data-wow-delay="0.9s"
              style={
                {
                  // visibility: "visible",
                  // animationDelay: "0.9s",
                  // animationName: "fadeInUp",
                }
              }
            >
              <div className="feature__img">
                <img src={feature4Png} alt="feature 4" />
              </div>
              <div className="feature__info">
                <div className="feature__num">&gt; 1000 кг</div>
                <div className="feature__descr">перерабатываем за 2 часа</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
