import React from "react"

import Layout from "../../components/Layout"

import HeadSection from "../../components/HeadSection"
import Screen from "./Screen"
import LocationSection from "../../components/Home/LocationSection"
import OfferSection from "../../components/Home/OfferSection"

const NotFound = () => (
  <>
    <HeadSection
      title="404 - Страница не найдена"
      description="Такой страницы к сожалению уже нет"
    />
    <Layout>
      <Screen />
      <LocationSection />
      <OfferSection />
    </Layout>
  </>
)

export default NotFound
